import React, { useState } from 'react';

import CheckBox from '../CheckBox';
import { Container, Label, BoxContainer, BoxChild } from './styles';

interface ILayout {
  selectedTypes? : Array<string>, 
  setSelectedTypes? : (selectedIdList : Array<string>) => void,
}

const ScheduleType: React.FC<ILayout> = (props) => {
  const { selectedTypes, setSelectedTypes } = props;
  
  const onClick = (item : string) => {
    if (!setSelectedTypes || !selectedTypes){
      return;
    }

    if (!selectedTypes) {
      setSelectedTypes(new Array<string>());
    }
    
    if (selectedTypes && selectedTypes.includes(item)){
      setSelectedTypes(selectedTypes.filter((itemF) => item !== itemF));
      return;
    }

    setSelectedTypes([...selectedTypes, item]);
  }

  return (
    <Container>
      <Label>Selecione uma Agenda: </Label>
      <BoxContainer>
        <BoxChild>
          <CheckBox label='Convênio' selected={selectedTypes ? selectedTypes.includes('convenio') : false} onClick={() => onClick('convenio')} />
        </BoxChild>
        <BoxChild>
          <CheckBox label='Particular' selected={selectedTypes ? selectedTypes.includes('particular') : false} onClick={() => onClick('particular')} />
        </BoxChild>
      </BoxContainer>
    </Container>
  );
};

export default ScheduleType;

