export const lightColors: { [key: string]: string } = {
  gray10: "#F5F5F5",
  gray15: "#F7F7F7",
  gray40: "#C6C6C6",
  gray20: "#EEEEEE",
  lightBlue: "#d3e8f2",
  white: "#FFFFFF",
};

export const darkColors: { [key: string]: string } = {
  red: "#E85E5E",
  yellow: "#FFB23E",
  gray80: "#5A5A5A",
  gray60: "#838484",
  gray70: "#797979",
  danger: "#E85E5E",
  warning: "#FFB23E",
  primary: "#0098E3",
  darkBlue: "#007de3",
  green: "#3CB371",
};

export const colors: { [key: string]: string } = {
  ...lightColors,
  ...darkColors,
};
