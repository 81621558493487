
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import {
  Container,
  DayColumn,
  DayHeader,
  TimeCell,
  TimeColumn,
  TimeHeader,
  ScheduleCell,
  ScheduledTime,
  ScheduleAvailableTime,
  ScheduleAvailableText,
  ScheduledText,
  DrText,
  PatientText,
  Time,
  Button
} from "./styles";

import Camera from "../Icons/camera";
import Location from "../Icons/location";
import { useConfigurations } from "hooks/configurations";

interface ILayout {
  linesTime: Array<{time: string, listScheduled: Array<{id: string, date: Date, time:string, professional: string, paciente: string, type: number}>, listAvailable: Array<{date: Date, time: string, professional: string, type: number, professionalCpf: string, scheduleCode: string, professionalId: number}>}>
  selectedDate: Date,
  filteredDate: Date,
  setSelectedDate: (selectedDate : Date) => void,
  selectedProfessional: string,
  selectedSpecialty: number,
}

const ScheduleGrid: React.FC<ILayout> = (props) => {
  const history = useHistory();
  const { setSelectedDate, selectedDate, filteredDate, linesTime, selectedProfessional, selectedSpecialty} = props;

  const { setSelectedSchedule, handleModals } = useConfigurations();


  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<string>(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}));
/**
   * REGRAS PARA TRABALHAR COM DATAS:
   * SE FOR SETAR DINAMICAMENTE DE ACORDO COM A DATA JÁ SELECIONADA USAR new Date(`${year}-${(parseInt(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}))-1)}-1`) 
   *   - NÃO USAR selectedDate.getMonth() para setar mês em datas, pois ele retorna mês de 0 a 11
   */

  const onClick = (day: string,time: string) => {
    setSelectedDate(new Date(parseInt(year), parseInt(month), parseInt(day),parseInt(time)));
  }

  const countLine = (
      schedule: Array<{id: string, date: Date, time: string, professional: string, type: number}>, 
      listAvailable: Array<{date: Date, time: string, professional: string, type: number, professionalId: number}>, day: Number
    ) => {
      
    const countScedule = schedule.filter((item) => item.date.getDate() == day ).length;
    const countListAvailable =  listAvailable.filter((item) => item.date.getDate() == day ).length;

    return countScedule + countListAvailable;
  }

  const ScheduleGridRender = () => {
    let dayColumns = Array.from({length: 7}, (_, i) => `${i}`);

    dayColumns = dayColumns.map((day) => {
      let dayInMonth = filteredDate.getDate();
      let returnCell = `${dayInMonth}/${filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})}`;

      let lastDay = new Date(filteredDate.getFullYear(), parseInt(filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})), 0).getDate();

      let calcDayIni = dayInMonth;
      let calcMonthIni = filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'});
      let calcDayFim = dayInMonth;
      let calcMonthFim = filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'});

      if (calcDayFim === lastDay){
        calcDayFim = 0;
        calcMonthFim = new Date(filteredDate.getFullYear(), (parseInt(filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})) + 1), 0).toLocaleDateString('pt-BR', { month: 'numeric'});
      }else if (calcDayIni === 1){
        calcDayIni = new Date(filteredDate.getFullYear(), (parseInt(filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'}))- 1), 0).getDate();
        calcMonthIni = new Date(filteredDate.getFullYear(), (parseInt(filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'}))- 1), 0).toLocaleDateString('pt-BR', { month: 'numeric'});
      }
      
      switch (day) {
        case '0':
          returnCell = `${calcDayIni-3}/${calcMonthIni}`;
          break;
        case '1':
          returnCell = `${calcDayIni-2}/${calcMonthIni}`;
          break;
        case '2':
          returnCell = `${calcDayIni-1}/${calcMonthIni}`;
          break;
        case '4':
          returnCell = `${calcDayFim+1}/${calcMonthFim}`;
          break;
        case '5':
          returnCell = `${calcDayFim+2}/${calcMonthFim}`;
          break;
        case '6':
          returnCell = `${calcDayFim+3}/${calcMonthFim}`;
          break;
        default:
          returnCell = `${dayInMonth}/${filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})}`;
      }

      return returnCell;
    });

    return (
      <Container>
        <TimeColumn>
          <TimeHeader></TimeHeader>
          {
            linesTime.map((line : {time: string, listScheduled: Array<{id: string, date: Date, time: string, professional: string, type: number}>, listAvailable: Array<{date: Date, time:string, professional: string, type: number, professionalId: number}>}) => {
              return (
                <TimeCell>
                    {line.time}
                </TimeCell>
              )
            })
          }
        </TimeColumn>
        {
          dayColumns.map((day) => {
            return(
              <DayColumn>
                <DayHeader>{day}</DayHeader>
                {
                  linesTime.map((line : {
                    time: string, 
                    listScheduled: Array<{id: string, date: Date, time: string, professional: string, paciente: string, type: number}>, 
                    listAvailable: Array<{date: Date, time: string, professional: string, type: number, professionalCpf: string, scheduleCode: string, professionalId: number}>
                  }) => {
                    return (
                      <ScheduleCell onClick={()=> onClick(day, line.time)} >
                        {
                          line.listAvailable.map((available) => {
                            if(available.date.getDate() === parseInt(day)){
                              return (
                                  <ScheduleAvailableTime onClick={()=> {
                                    handleModals('schedule')

                                    setSelectedSchedule({
                                      professional: available.professional,
                                      dates: [
                                        {
                                          date: available.date,
                                          time: available.time,
                                        },
                                      ],
                                      professionalCpf: available.professionalCpf,
                                      professionalScheduleId: available.scheduleCode,
                                      speacialtyId: selectedSpecialty,
                                      professionalId: available.professionalId,
                                    })

                                  }} >
                                    <ScheduleAvailableText>
                                        <DrText> 
                                          {available.professional}
                                        </DrText>
                                        <Time>
                                          {available.time}
                                        </Time>
                                    </ScheduleAvailableText>
                                </ScheduleAvailableTime>
                                )
                            }
                            return (<></>)
                          })
                        }
                        {
                          line.listScheduled.map((scheduled) => {
                            if(scheduled.date.getDate() === parseInt(day)){
                              return (
                                <ScheduledTime onClick={()=> {}} >
                                  <ScheduledText>
                                    <DrText> 
                                      {scheduled.type == 1 ? 
                                          <Camera width="15" height="12" viewBox="0 3 20 5" fill='#3CB371'/> 
                                        : 
                                          <Location width="14" height="20" viewBox="-3 5 20 5" fill='#3CB371' />} {scheduled.professional}
                                    </DrText>
                                    <PatientText>
                                      {scheduled.paciente}
                                    </PatientText>
                                    <Time>
                                      {scheduled.time}
                                    </Time>
                                  </ScheduledText>
                                </ScheduledTime>
                                )
                            }
                            return (<></>)
                          })
                        }
                        {
                          !!countLine(line.listScheduled, line.listAvailable, parseInt(day)) &&
                              <Button 
                                onClick={ ()=> history.push({ pathname: '/schedules', search:`data=${filteredDate.toLocaleDateString('pt-BR', { year: 'numeric'})}-${filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})}-${day.split('/')[0]}&medico=${selectedProfessional}&especialidade=${selectedSpecialty}` }) }
                              > 
                                Ver todos (
                                  +{ countLine(line.listScheduled, line.listAvailable, parseInt(day))} )
                              </Button>
                        }
                      </ScheduleCell>
                    )
                  })
                }
              </DayColumn>
            )
          })
        }
      </Container>
    );
  }

  return (
    <ScheduleGridRender />
  );
};

export default ScheduleGrid;
