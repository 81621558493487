/* eslint-disable react-hooks/exhaustive-deps */
import {
  formatToLocaleDate,
  isCpfDigitsValid,
  isEmailValid,
  isFullName,
  isValidBirthDate,
  isValidCep,
  isValidAddress,
  isDistrictValid,
  isCityValid,
  isStateValid,
  isCountryValid,
  isNumberValid,
  isInsuranceValid
} from "components/AttendanceCreationForm/helper";
import AttendanceCreationModal from "components/AttendanceCreationModal";
import LoadingOverlay from "components/LoadingOverlay";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getAdditionalSchedules } from "services/Professional";
import { postAgendamentos } from "services/Agendamentos";
interface ILoadings {
  mounting: boolean;
  [key: string]: boolean;
}

const initialLoadingState = {
  mounting: false,
};

const initialSelectedSchedule = {
  speacialtyId: 5,
  professionalId: 5,
  professionalCpf: '09009009090',
  professionalScheduleId: 19,
  professional: "Dr. Bezerra Filho",
  dates: [
    {
      date: "2021-10-11",
      time: "10:00",
    },
  ],
};

const initialAttendanceCreationFields = {
  insurance: {
    description: "Particular",
    value: "particular",
  },
};

const initialAttendanceCreationStep = "creation";

const initialSelectedWeekDays = [1, 2, 3, 4, 5, 6, 7];

const ConfigurationsContext = createContext({});

const ConfigurationsProvider: React.FC = ({ children }) => {
  const [loadings] = useState<ILoadings>(initialLoadingState);

  const [errors, setErrors] = useState<any>();

  const [selectedSchedule, setSelectedSchedule] = useState<any>(
    initialSelectedSchedule
  );

  const [showAdditionalSchedule, setShowAdditionalSchedules] =
    useState<boolean>(false);

  const [filteredDoctorSchedule, setFilteredDoctorSchedule] =
    useState<any>(false);

  const [selectedWeekDays, setSelectedWeekDays] = useState<any>(
    initialSelectedWeekDays
  );

  const [attendanceCreationFields, setAttendanceCreationFields] = useState<any>(
    initialAttendanceCreationFields
  );

  const [attendanceCreationStep, setAttendanceCreationStep] = useState<string>(
    initialAttendanceCreationStep
  );

  const [openedModals, setOpenedModals] = useState<any>({
    schedule: false,
  });

  const isAdditionalScheduleSelected = (selectedId: number) =>
    selectedSchedule.dates.some(({ id }: any) => id === selectedId);

  const renderRightContainer =
    attendanceCreationStep === initialAttendanceCreationStep &&
    showAdditionalSchedule;

  const switchShowAdditionalSchedule = () =>
    setShowAdditionalSchedules((oldState) => !oldState);

  const handleSelectedWeekDays = (weekDay: number) =>
    setSelectedWeekDays((oldSelectedWeekDays: any) => {
      let oldSelectedWeekDaysCopy = [...oldSelectedWeekDays];

      const isWeekDaySelected = oldSelectedWeekDaysCopy.includes(weekDay);

      if (isWeekDaySelected) {
        oldSelectedWeekDaysCopy = oldSelectedWeekDaysCopy.filter(
          (currentWeekDay) => currentWeekDay !== weekDay
        );
      } else {
        oldSelectedWeekDaysCopy.push(weekDay);
      }

      return oldSelectedWeekDaysCopy;
    });

  const handleModals = (modal: any) =>
    setOpenedModals((oldOpenedModals: any) => ({
      ...oldOpenedModals,
      [modal]: !oldOpenedModals[modal],
    }));

  const handleAttendanceFields = (key: string, value: any) =>
    setAttendanceCreationFields((oldFields: any) => ({
      ...oldFields,
      [key]: value,
    }));

  const handleAdditionalSchedules = (newDate: any) => {
    setSelectedSchedule((oldSelectedSchedule: any) => {
      let oldSelectedWeekDaysCopy = { ...oldSelectedSchedule };

      const index = oldSelectedWeekDaysCopy.dates.findIndex(
        (currentDate: any) => currentDate.id === newDate.id
      );

      if (index !== -1) {
        oldSelectedWeekDaysCopy.dates.splice(index, 1);
      } else {
        oldSelectedWeekDaysCopy.dates.push(normalizeDateTime(newDate));
      }

      return oldSelectedWeekDaysCopy;
    });
  };

  const normalizeDateTime = (newDate: any) => {
    const date = new Date(newDate.date);
    const hours = newDate.time.split(':');
    return Object.assign({
      dateTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours[0], hours[1], 0)
    }, newDate)
  }

  const formattedDescription = `${
    selectedSchedule.professional
  } | ${formatToLocaleDate(selectedSchedule.dates[0].date)} ${
    selectedSchedule.dates[0].time
  }`;

  const attendanceModalInformation: { [index: string]: any } = {
    creation: {
      title: "Novo agendamento",
      description: formattedDescription,
    },
    confirmation: {
      title: "Confirmar Agendamento",
      description: null,
    },
  };

  const handleInputEvent = (value: any, name: any) => {
    handleAttendanceFields(name, value);
  };

  const clearSelectedScheduleDates = () =>
    setSelectedSchedule((oldSchedule: any) => ({
      ...oldSchedule,
      dates: oldSchedule.dates.filter((date: any) => !date.id),
    }));

  const fetchAdditionalSchedules = async () => {
    try {
      const { professionalScheduleId } = selectedSchedule;

      const {
        insurance: { value },
      } = attendanceCreationFields;

      const bodyRequest = {
        doctorId: professionalScheduleId,
        weekDays: selectedWeekDays,
        hasInsurance: value !== "particular",
      };

      const { data: additionalSchedules } = await getAdditionalSchedules(
        bodyRequest
      );

      clearSelectedScheduleDates();

      setFilteredDoctorSchedule(additionalSchedules);
    } catch (error) {
      console.log(error);
      alert("Ocorreu um erro ao retornar a agenda adicional " + error);
    }
  };

  const storeSchedule = async () => {
    const requestBody = {
      ...attendanceCreationFields,
      ...selectedSchedule,
    };

    const response = await postAgendamentos({
      especialidade: requestBody.speacialtyId,
      profissionalid: requestBody.professionalId,
      professionalcpf: requestBody.professionalCpf,
      pacienteid: requestBody.id,
      tipoagendamento: requestBody.inPerson ? 'presencial': 'telemedicina',
      nome_convenio: requestBody.insurance.description,
      numconvenio: requestBody.insurance.value,
      agendamentos: requestBody.dates,
      pacientenome: requestBody.name.split(' ')[0],
      pacientecelular: requestBody.mainTelephoneNumber,
      pacientetelefonefixo: requestBody.secondaryTelephoneNumber,
      pacienteemail: requestBody.email ,
      pacientesobrenome: requestBody.name.replace(requestBody.name.split(' ')[0], ''),
      pacientesexo: requestBody.gender,
      pacientedatanascimento: requestBody.birthDate,
      pacientetipoperfil: '',
      pacientecpf: requestBody.cpf,
      pacienteendereco: requestBody.address,
      pacientecep: requestBody.cep,
      pacientecidade: requestBody.city,
      pacientepais: requestBody.country,
      pacientenumero: requestBody.number,
      pacienteuf: requestBody.state,
      pacientedestrito: requestBody.destrito,
      pacientecomplemento: requestBody.complement,
      pacienteestadocivil: requestBody.civilStatus,
      pacienterg: requestBody.rg,
    });
    setAttendanceCreationStep('creation')
    setAttendanceCreationFields(initialAttendanceCreationFields)
    handleModals('schedule');
    alert('Agendamento criado com sucesso!')

  };

  const addError = (field: any, message: any) => {
    const newErrors = { ...errors };
    newErrors[field] = message;

    setErrors(newErrors);
  };

  const removeError = (field: any) => {
    const newErrors = { ...errors };
    delete newErrors[field];

    setErrors(newErrors);
  };

  const validateInput = (input: string, value: any) => {
    switch (input) {
      case "email":
        if (!isEmailValid(value)) addError("email", "Informe um email válido");
        else removeError("email");
        break;

      case "cpf":
        if (!isCpfDigitsValid(value)) addError("cpf", "Informe um CPF válido");
        else removeError("cpf");
        break;

      case "phone":
        if (value.length < 10)
          addError("mainTelephoneNumber", "Informe um telefone válido");
        else removeError("phone");
        break;

      case "name":
        if (!isFullName(value)) addError("name", "Informe o nome completo");
        else removeError("name");
        break;

      case "birthDate":
        if (!isValidBirthDate(value))
          addError("birthDate", "Informe uma data dd/mm/aa");
        else removeError("birthDate");
        break;

      case "cep":
        if(!isValidCep(value)) addError("cep", "Informe um cep válido")
        else removeError("cep")
        break;
        
      case "address":
        if(!isValidAddress(value)) addError("address", "Endereço é obrigatório ")
        else removeError('address')
        break;

      case "district":
        if(!isDistrictValid(value)) addError("district", "Bairro é obrigatório")
        else removeError("district")
        break;

      case "city":
        if(!isCityValid(value)) addError("city", "Cidade é obrigatório")
        else removeError("city")
        break;

      case "state": 
        if(!isStateValid(value)) addError("state", "Estado é obrigatório")
        else removeError("state")
        break;

      case "country":
        if(!isCountryValid(value)) addError("country","País é obrigatório")
        else removeError("country")
        break;

      case "number":
        if(!isNumberValid(value)) addError("number", "Número é obrigatório")
        else removeError("number")
        break;

      case "insurance": 
      console.log(value.nome)
        if(!isInsuranceValid(value)) addError("insurance", "Selecione o convênio")
        else removeError("insurance")
        break;
      
      default:
        break;
    }
  };

  useEffect(() => {
    selectedWeekDays.length && fetchAdditionalSchedules();
  }, [selectedWeekDays, attendanceCreationFields.insurance]);

  // useEffect(() => {
  //   if (!match || !match.params) return 
  //   setToken(match.params.token)
  // }, [match])

  return (
    <ConfigurationsContext.Provider
      value={{
        handleModals,
        storeSchedule,
        selectedWeekDays,
        selectedSchedule,
        handleInputEvent,
        errors,
        setSelectedSchedule,
        filteredDoctorSchedule,
        showAdditionalSchedule,
        handleSelectedWeekDays,
        handleAttendanceFields,
        attendanceCreationStep,
        validateInput,
        attendanceCreationFields,
        switchShowAdditionalSchedule,
        setAttendanceCreationStep,
        handleAdditionalSchedules,
        selectedAdditionalSchedules: selectedSchedule.dates,
        isAdditionalScheduleSelected,
      }}
    >
      <AttendanceCreationModal
        title={attendanceModalInformation[attendanceCreationStep].title}
        description={
          attendanceModalInformation[attendanceCreationStep].description
        }
        open={openedModals.schedule}
        renderRightContainer={renderRightContainer}
        toggle={() => handleModals("schedule")}
        icon="calendar"
      />

      {loadings.mounting ? <LoadingOverlay /> : children}
    </ConfigurationsContext.Provider>
  );
};

const useConfigurations = (): any => useContext(ConfigurationsContext);

export { ConfigurationsProvider, useConfigurations };
