import React from "react";

import SelectedIcon from "../Icons/selected";
import {
  CheckboxContainer,
  CheckboxLabel,
  CheckboxSelected,
  Checkbox,
} from "./styles";

interface ILayout {
  selected: boolean;
  label: string;
  onClick?: () => void;
}

const CheckboxComponent: React.FC<ILayout> = (props) => {
  const { selected, label, onClick } = props;

  return (
    <CheckboxContainer onClick={onClick}>
      <Checkbox>
        {selected && 
          <CheckboxSelected >
            <SelectedIcon />
          </CheckboxSelected>
        }
      </Checkbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export default CheckboxComponent;
