import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import InitialScreen from ".././views/InitialScreen";
import AgendamentoScreen from ".././views/Schedules";

const AppRoutes: React.FC = () => (
  <Layout>
    <Switch>
      <Route path='/calendar' exact component={InitialScreen} />
      <Route path='/schedules'  exact component={AgendamentoScreen} />
      <Redirect to='/calendar' exact />
    </Switch>
  </Layout>
);

export default AppRoutes;
