import styled from "styled-components";
import { colors } from "styles";

export const FormRoot = styled.div``;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormField = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 5px;
  width: ${(props: any) => props.width}%;

  @media (max-width:1024px) {
    width: 100%;
  }

`;

export const ErrorLabel = styled.span`
  font-size: 10px;
  color: ${colors.red};
`

export const ButtonText = styled.div`
  margin-left: 5px;
`;

export const FormFields = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const FormFooter = styled.div`
  display: flex;
  padding-top: 15px;
  margin-top: 20px;
  border-top: 1px solid ${colors.gray40};
  flex-wrap: wrap;

`;

export const FormFooterOptions = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
`

export const CheckboxGroup = styled.div`
  display: flex;
`

export const CheckboxWrapper = styled.div`
  margin: 5px;
`

export const FormFooterObservation = styled.div`
width: 60%;
height:100%;
`

export const FormSubmitArea = styled.div  `
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 8px;
  width: 100%;
`