import './App.css';
import { ConfigurationsProvider } from "./hooks/configurations";
import Routes from "./routes";
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  return (
    <ConfigurationsProvider>
      <GlobalStyles />
      <Routes />
    </ConfigurationsProvider>
  );
}

export default App;
