/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Agendamentos from "../../components/Agendamentos";

const AgendamentoScreen: React.FC = (props: any) => {
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [professionalCpf, setProfessionalCpf] = useState<string>('');
  const [specialty, setSpecialty] = useState<number>(0);

  useEffect(() => {

    if(props && props.location && props.location.search && props.location.search.includes('data')){
      let data = props.location.search.split('&');
      data = data[0].includes('data') ? data[0] : data[1];
      data = data.split('=')[1];
      setDateFilter(new Date(data));
    }
    if(props && props.location && props.location.search && props.location.search.includes('medico')){
      let medico = props.location.search.split('&');
      medico = medico[0].includes('medico') ? medico[0] : medico[1];
      medico = medico.split('=')[1];
      setProfessionalCpf(medico);
    }
    if(props && props.location && props.location.search && props.location.search.includes('especialidade')){
      let especialidade = props.location.search.split('&');
      especialidade = especialidade[0].includes('especialidade') ? especialidade[0] : especialidade[1];
      especialidade = especialidade.split('=')[1];
      setSpecialty(especialidade);
    }
  }, [props])

  return (

    <Agendamentos dateFilter={dateFilter} professionalCpf={professionalCpf} specialty={specialty}/>

  );
};

export default AgendamentoScreen;
