import { weekDays } from "components/AttendanceCreationForm/helper";
import CheckboxComponent from "components/CheckBox";
import Label from "components/Label";
import { useConfigurations } from "hooks/configurations";
import { useEffect } from "react";
import {
  MultipleScheduleScreenRoot,
  WeekDaysRender,
  CheckboxWrapper,
  AdditionalDatesRender,
  AdditionalDateWrapper,
  AdditionalDateTitle,
  SchedulePillsRender,
  SchedulePill,
} from "./styles";

export default function MultipleScheduleScreen() {

  const {
    handleSelectedWeekDays,
    handleAdditionalSchedules,
    selectedWeekDays,
    filteredDoctorSchedule,
    selectedAdditionalSchedules,
    isAdditionalScheduleSelected,
  } = useConfigurations();

  const filteredDoctorScheduleKeys = Object.keys(filteredDoctorSchedule);

  const weekDaysRender = weekDays.map(({ day, description }) => (
    <CheckboxWrapper key={day}>
      <CheckboxComponent
        onClick={() => handleSelectedWeekDays(day)}
        selected={selectedWeekDays.includes(day)}
        label={description}
      />
    </CheckboxWrapper>
  ));

  const additionalDatesRender = filteredDoctorScheduleKeys.map((key) => (
    <AdditionalDateWrapper>
      <AdditionalDateTitle>{key}</AdditionalDateTitle>

      <SchedulePillsRender>
        {filteredDoctorSchedule[key].map((pill: any) => (
          <SchedulePill
            onClick={() => handleAdditionalSchedules(pill)}
            selected={isAdditionalScheduleSelected(pill.id)}
            key={pill.id}
          >
            {pill.time}
          </SchedulePill>
        ))}
      </SchedulePillsRender>
    </AdditionalDateWrapper>
  ));

  return (
    <MultipleScheduleScreenRoot>
      <Label label="Filtrar por dia da semana:">
        <WeekDaysRender>{weekDaysRender}</WeekDaysRender>
      </Label>

      <AdditionalDatesRender>{additionalDatesRender}</AdditionalDatesRender>
    </MultipleScheduleScreenRoot>
  );
}
