import AttendanceConfirmation from "components/AttendanceConfirmation";
import AttendanceCreationForm from "components/AttendanceCreationForm";
import Modal from "components/Modal";
import { useConfigurations } from "hooks/configurations";
import { ModalBody } from "./styles";
const AttendanceCreationModal = (props: any) => {
  const { attendanceCreationStep } = useConfigurations();

  const renderAttendanceSteps: { [index: string]: any } = {
    creation: <AttendanceCreationForm />,
    confirmation: <AttendanceConfirmation />,
  };

  return (
    <Modal {...props}>
      <ModalBody>{renderAttendanceSteps[attendanceCreationStep]}</ModalBody>
    </Modal>
  );
};

export default AttendanceCreationModal;
