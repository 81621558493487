import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="-7 4 35 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.9067 4.0933L11.3089 19.6877C10.8909 20.1041 10.2141 20.1041 9.79772 19.6877L0.312276 10.1416C-0.104092 9.72355 -0.104092 9.04421 0.312276 8.62868L2.5787 6.36394C2.99676 5.94673 3.67357 5.94673 4.08993 6.36394L10.5613 12.8783L23.1266 0.312276C23.5429 -0.104092 24.2197 -0.104092 24.6395 0.312276L26.9059 2.58039C27.3265 2.9976 27.3265 3.67694 26.9067 4.0933Z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
