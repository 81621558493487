import SelectInput from "components/SelectInput";
import { useEffect, useState } from "react";
import { getInsurances } from "services/Insurances";

const defaultInsuranceState = [
  {
    description: "Particular",
    value: "particular",
  },
];

export default function InsuranceSelection(props: any) {
  const [insurances, setInsurances] = useState<any>(defaultInsuranceState);
  const [isFetching, setIsFetching] = useState<any>(true);

  const normalizeInsuranceInformation = (data: any) => [
    {
      description: "Particular",
      value: "particular",
    },
    ...data.map((insurance: any) => ({
      ...insurance,
      description: insurance.nome,
      value: insurance.id_externo,
    })),
  ];

  const fetchInsuranceInformation = async () => {
    try {
      const { data: { data: fetchedInsurances } = {} } = await getInsurances();
      setInsurances(normalizeInsuranceInformation(fetchedInsurances));
    } catch (error: any) {
      alert(
        "Ocorreu um erro ao retornar as informações dos convênios" +
          error.message +
          JSON.stringify(error)
      );
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchInsuranceInformation();
  }, []);

  return (
    <SelectInput
      placeholder="Selecione o convênio..."
      options={insurances}
      isLoading={isFetching}
      {...props}
    />
  );
}
