import { colors } from "./../../styles/index";
import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor:pointer;
`;

export const CheckboxLabel = styled.div`
  margin-left: 7px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: ${colors.gray60}
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 2px solid ${colors.gray60};
  position: relative;
`;

export const CheckboxSelected = styled.div`
  background: ${colors.primary};
  border-color: ${colors.primary};
  display: flex;
  width: 14px;
  height: 14px;
  margin: -2px;
  border-radius: 2px;
`;


export const Line = styled.div`
  
`