import { colors } from "../../styles/index";
import styled from "styled-components";

export const Label = styled.div`
  color: ${colors.gray60};
  margin-left: 5px;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  margin-bottom: 10px;
  padding-left: 5px;
`;

export const SelectContainer = styled.select`
  display: flex;
  margin-left: 5px;
  background-color:${colors.gray15};
  border: none;
  height: 32px;
  padding: 5px;
  color: ${colors.gray60};
  width: 235px;
  border-radius: 4px;
`;

export const SelectOption = styled.option`
  margin: 5px;
  padding: 5px;
`;

export const NoData = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 15px;
  color: ${colors.gray40};
`;


