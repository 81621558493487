import { Description } from 'components/Modal/styles';
import { Container } from 'components/ScheduleType/styles';
import React, { useEffect, useState } from 'react';

import SelectInput from '../SelectInput';

import { NoData, Label } from './styles';

interface ILayout {
  professionals : Array<{ id: number, value: string, label: string, selected: boolean }>,
  setProfessionals? : () => void,
  selectedIdList? : Array<string>,
  setSelectedIdList? : (selectedIdList : Array<string>) => void,
  setSelectedProfessionalId? : (selectedProfessionalId : number) => void,
  search?: () => void;
}

const ProfessionalFilter: React.FC<ILayout> = (props) => {
  const { search, professionals, selectedIdList, setSelectedIdList, setSelectedProfessionalId } = props;

  const [selectedItem, setSelectedItem] = useState(null);

  // const { professionals, setProfessionals } = useState(Array<{ id: number, label: string, selected: boolean }>);

  const normalizeSelect = () => {
    return professionals.map( (item: any) => {
      return {
        description: item.label,
        value: item.value,
        selected: item.selected,
        id: item.id,
      }
    });
  }

  return (
    <Container>
      <Label>Selecione um Profissional:</Label>

      {
        (professionals && professionals.length > 0) && (
          <SelectInput
            selectedOption={normalizeSelect()}
            name="professionals"
            onSelect={ (val: any) => {
              !!setSelectedIdList && setSelectedIdList( [`${val.value}`] );
              !!setSelectedIdList && setSelectedItem (val.description)
              !!setSelectedProfessionalId && setSelectedProfessionalId(val.id)
            }}
            options={normalizeSelect()}
            placeholder={!!selectedItem ?  selectedItem : 'Selecione...'}
          />
        )
      }
      {
          (!professionals || professionals.length ===0) && (
            <NoData>Nenhum profissional encontrado</NoData>
          )
        }
    </Container>
  );
};

export default ProfessionalFilter;

