import {
  formatToLocaleDate,
  getWeekDayLong,
} from "components/AttendanceCreationForm/helper";
import Button from "components/Button";
import GetIcon from "components/Icons";
import Label from "components/Label";
import TextArea from "components/TextArea";
import { useConfigurations } from "hooks/configurations";

import {
  AttendanceConfirmationRoot,
  SelectedPatientInformation,
  SelectedPatientName,
  SelectedProfessionalName,
  SelectedScheduleInformation,
  SelectedSchedules,
  Information,
  Badge,
  BadgeText,
  ScheduleBadge,
  Observation,
  AttendanceConfirmationFooter,
} from "./styles";

const AttendanceConfirmation = (props: any) => {
  const {
    attendanceCreationFields: {
      name,
      cpf,
      email,
      birthDate,
      inPerson,
      insurance,
      mainTelephoneNumber,
      secondaryTelephoneNumber,
    },
    selectedSchedule: { professional, dates = [] },
    handleInputEvent,
    setAttendanceCreationStep,
    storeSchedule,
  } = useConfigurations();

  const renderDates = dates.map((date: any) => (
    <ScheduleBadge key={date}>
      {getWeekDayLong(date.date)}&nbsp;-&nbsp;
      {formatToLocaleDate(date.date)} {date.time}
    </ScheduleBadge>
  ));

  return (
    <AttendanceConfirmationRoot>
      <SelectedPatientName>{name}</SelectedPatientName>

      <SelectedPatientInformation>
        <Information>
          <Label label="CPF:">{cpf}</Label>
        </Information>

        <Information>
          <Label label="Data de Nascimento:">{birthDate}</Label>
        </Information>

        <Information>
          <Label label="Telefone 1:">{mainTelephoneNumber}</Label>
        </Information>
        
        <Information>
          <Label label="E-mail:">{email}</Label>
        </Information>
      </SelectedPatientInformation>

      <SelectedScheduleInformation>
        <SelectedProfessionalName>{professional}</SelectedProfessionalName>

        <Badge>
          <GetIcon icon={inPerson ? "person" : "video"} />
          <BadgeText>{inPerson ? "Presencial" : "Telemedicina"}</BadgeText>
        </Badge>

        <Badge>{insurance?.description}</Badge>
      </SelectedScheduleInformation>

      <SelectedSchedules>{renderDates}</SelectedSchedules>

      <Observation>
        <Label label="Observação">
          <TextArea
            onChange={handleInputEvent}
            name="observation"
            placeholder="Digite suas observações aqui..."
          />
        </Label>
      </Observation>

      <AttendanceConfirmationFooter>
        <Button
          onClick={() => setAttendanceCreationStep("creation")}
          color="gray20"
        >
          Voltar
        </Button>

        <Button onClick={storeSchedule} color="primary">Confirmar agendamento</Button>
      </AttendanceConfirmationFooter>
    </AttendanceConfirmationRoot>
  );
};

export default AttendanceConfirmation;
