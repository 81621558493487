import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ? props.width : 15}
      height={props.height ? props.height : 15}
      viewBox="0 1 20 5"
      fill="#838484"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.53173 0H7.91904C8.76149 0 9.45076 0.67396 9.45076 1.53173V6.69365C9.45076 7.5361 8.76149 8.22538 7.91904 8.22538H1.53173C0.689278 8.22538 0 7.5361 0 6.69365V1.53173C0 0.689278 0.689278 0 1.53173 0ZM12.6062 0.949686C12.6827 0.903735 12.7746 0.857783 12.8666 0.842465C13.4639 0.70461 14 1.16413 14 1.73087V6.46391C14 6.6324 13.9541 6.78557 13.8775 6.92343C13.6171 7.36763 13.0657 7.5208 12.6215 7.26041L10.2167 5.88185V2.32824L12.6062 0.949686Z"
        fill={props.fill ? props.fill : '#868686'}
      />
    </svg>
  )
}

export default SvgComponent
