import React, { useState, useEffect} from 'react';

import PreviousIcon from "../Icons/previous";
import NextIcon from "../Icons/next";

import { Line, Container, Month, WeekDays, WeekDay, Day, SelectedDay, Header, Blank, Week, Calendar, Previous, Next} from './styles';

interface ILayout {
  selectedDate: Date;
  setSelectedDate: (selectedDate : Date) => void,
}

const CalendarPicker: React.FC<ILayout> = (props) => {

  const {selectedDate, setSelectedDate} = props;

  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [monthString, setMonthString] = useState<string>(selectedDate.toLocaleDateString('pt-BR', { month: 'long'}));
  const [month, setMonth] = useState<string>(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}));

  /**
   * REGRAS PARA TRABALHAR COM DATAS:
   * setSelectedDate DEVE-SE USAR NO FORMATO NEW DATE('YYYY-MM-DD'), MÊS DE 1 A 12
   * SE FOR SETAR DINAMICAMENTE DE ACORDO COM A DATA JÁ SELECIONADA USAR new Date(`${year}-${(parseInt(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}))-1)}-1`) 
   *   - NÃO USAR selectedDate.getMonth() para setar mês em datas, pois ele retorna mês de 0 a 11
   */

  const DaysInAMonthMatrix = () => {
    let lastDay = new Date(parseInt(year), parseInt(month), 0).getDate();
    
    let firstDay = new Date(`${year}-${parseInt(month)}-1`);
  
    let firstWeek = Array.from({length: firstDay.getDay()}, (_, i) => 'x');
    let firstSaturday = 0;
    
    const rangeDay = (7-firstWeek.length);
  
    for(let ix = 1; ix <= rangeDay; ix++){
      firstWeek.push(`${ix}`);
      firstSaturday = ix;
    }
    
    let displayDays = [];
    displayDays.push(firstWeek);
    // = new Date(`${year}-${month}-01`);
    let week: any[] = [];

    let day = firstSaturday+1;

    for(day; day <= lastDay; day++){
      if (week.length === 7){
        displayDays.push(week);
        week = [];
      }

      week.push(day);

      if (day === lastDay){
        displayDays.push([...week,...Array.from({length: (7-week.length)}, (_, i) => 'x')]);
        week = [];
      }
    }

    const selectDate = (day: any) => {
      if (!setSelectedDate || !selectedDate) return;

      setSelectedDate(new Date(`${year}-${month}-${day}`));
    }
    
    return (
      <Calendar>
        {displayDays.map((weekDisplay: any) => {
          return (
            <Week>
             {
              weekDisplay?.map((day:any) => {
                if (day === 'x') return (<Blank/>)
                if(selectedDate.getDate() === (new Date(`${year}-${month}-${day}`).getDate())){
                  return (<SelectedDay onClick={()=> selectDate(day)}>{day}</SelectedDay>)
                }
                return (<Day onClick={()=> selectDate(day)}>{day}</Day>)
              })
             } 
            </Week>
          )
        })}
      </Calendar>
    );
  }
  
  const getPreviousMonth = () => {
    if(month === '1') {
      setSelectedDate(new Date(`${(parseInt(year)-1)}-12-1`))

      return;
    }
    
    setSelectedDate(new Date(`${year}-${(parseInt(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}))-1)}-1`));
  }

  const getNextMonth = () => {
    if(month === '12') {
      setSelectedDate(new Date(`${(parseInt(year)+1)}-01-1`));
      
      return;
    }
    
    setSelectedDate(new Date(`${year}-${(parseInt(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}))+1)}-1`));
  }
  
  useEffect(() => {
    setMonthString(selectedDate.toLocaleDateString('pt-BR', { month: 'long'}));
    setMonth(selectedDate.toLocaleDateString('pt-BR', { month: 'numeric'}));
    setYear(selectedDate.toLocaleDateString('pt-BR', { year: 'numeric'}));
  }, [selectedDate]);
  
  return (
    <Container>
      <Header>
        <Previous onClick={getPreviousMonth}>
          <PreviousIcon/>
        </Previous>
        <Month> {`${monthString}/${year.slice(-2)}`} </Month>
        <Next onClick={getNextMonth}>
          <NextIcon/>
        </Next>
      </Header>
      <WeekDays>
        <WeekDay>D</WeekDay>
        <WeekDay>S</WeekDay>
        <WeekDay>T</WeekDay>
        <WeekDay>Q</WeekDay>
        <WeekDay>Q</WeekDay>
        <WeekDay>S</WeekDay>
        <WeekDay>S</WeekDay>
      </WeekDays>
      <DaysInAMonthMatrix/>
      <Line />
    </Container>
  );
};

export default CalendarPicker;

