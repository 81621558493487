import { api, integration } from "..";

export const getAgendamentos = (searchTerm: any = '') =>
  api.get(
    `/agendamentos?${searchTerm}`
  );

export const postAgendamentos = (body: any) =>
  api.post(
    `/agendamentos/recepcao/agendamento`, body
  );

export const getAgendamentosTasy = (searchTerm: any = '') =>
  integration.get(
    `/recepcao/agendamentos?${searchTerm}`
  );

export const getLivresTasy = (searchTerm: any = '') =>
  integration.get(
    `/recepcao/livres?${searchTerm}`
  );
