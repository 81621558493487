import { StyledButton } from "./styles";

export default function Button(props: any) {
  const { onClick = () => {} } = props;

  return (
    <StyledButton
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
      {...props}
    />
  );
}
