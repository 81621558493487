import React from 'react';

import styles from './style.module.css';

const Label = (props: any) => {
  
  const {
    color, // string to set the color
    label, // string to set the label
    children, // regular children components
    className, // string to set an auxiliary class
    required // boolean to define if is required field
  } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      <small className={styles.label} style={{ color }}>
        {label} {required && <span className={styles.required}>*</span>}
      </small>

      {children}
    </div>
  );
};

export default Label;
