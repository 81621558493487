import styled from 'styled-components';

/**
 * Layout
 * H = Header
 * CT = Content
 */

export const Grid = styled.div`
  margin: 15px;
`;
