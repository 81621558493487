import { colors } from "./../../styles/index";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const Month = styled.div`
  color: ${colors.primary};
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  align-text: center;
  justify-content: center;
  display: inline-block;
  text-align: center;
  width: 130px;
  text-transform: capitalize;
`;

export const Container = styled.div`
  margin-bottom: 10px;
  display: grid;
  padding: 5px;
`;

export const Previous = styled.button`
  border-radius: 2px;
  border: none;
  background-color: ${colors.primary};
  display: inline-block;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  align-items: center;
  display: grid;
  justify-content: center;
`;

export const Next = styled.button`
  border-radius: 2px;
  border: none;
  background-color: ${colors.primary};
  display: inline-block;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  align-items: center;
  display: grid;
  justify-content: center;
`;

export const Week = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 2px;
  padding-inline-start: 0px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  align-items: center;
  justify-content: center;
`;
export const WeekDays = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline-start: 0px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  padding: 0px 4px;
  margin-bottom: 10px
`;

export const WeekDay = styled.div`
  display: inline-block;
  color: ${colors.gray60};
  margin-left: 5px;
  margin-right: 5px;
  list-style-type: none;
  text-align: center;
  padding: 4px;
  min-width: 22px;
  font-size: 12px
`;

export const SelectedDay = styled.div`
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 2px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  padding: 0px;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  line-height: 13px;
  display: grid;
`;

export const Day = styled.div`
  border-radius: 2px;
  margin-bottom: 5px;
  color: ${colors.gray40};
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  padding: 0px;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  line-height: 13px;
  display: grid;
  &:hover {
    background-color:${colors.primary};
    color: ${colors.white};
    cursor: pointer;
  }
`;
export const UnavailableDay = styled.div`
  border-radius: 2px;
  color: ${colors.red};
  border-radius: 2px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  padding: 0px;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  line-height: 13px;
  display: grid;
`;
export const Blank = styled.div`
  border-radius: 2px;
  margin-bottom: 5px;
  color: ${colors.gray40};
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  padding: 0px;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  line-height: 13px;
  display: grid;
  background-color: ${colors.white};
`;
export const Calendar = styled.div`
  display: grid;
`;
export const Line = styled.div`
  width: 100%;
  padding-top: 15px;
  border-bottom: 1px solid ${colors.gray40};
`;