
/** @TODO - REFATORAR PARA STYLED COMPONENTS */

import React, { useState, useRef } from 'react';
import styles from './style.module.css';
import TextInput from '../TextInput';

const SelectInput = (props: any) => {
  const {
    onSearch, // function triggered everytime the user type to search between options
    isLoading,
    placeholder, // string to set the placeholder when no selectedOptions is informed
    options = [], // array of options. each option should be an object with value and description params
    selectedOption, // object of selected option (object with value and description params)
    onOpen = () => {}, // function triggered everytime the component is opened
    onClose = () => {}, // function triggered everytime the component is closed
    onSelect = () => {}, // function triggered when user select an option
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(null);

  const searchTimeout = useRef<any>();

  const onSearchHandler = (val : any) => {
    setSearchValue(val);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
      searchTimeout.current = null;
    }

    searchTimeout.current = setTimeout(() => {
      if (onSearch) return onSearch(val);

      if (!val) return setFilteredOptions(null);

      const optionsWithSearchValue = options
        .filter(({ description }: any) => description.includes(val));

      setFilteredOptions(optionsWithSearchValue);
    }, 500);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
    onClose();
  };

  const onOpenHandler = () => {
    if (isLoading) return;

    setIsOpen(true);
    onOpen();
  };

  const onSelectHandler = (option: any) => {
    onSearchHandler('');
    onSelect(option);
    onCloseHandler();
  };

  const backdropRender = isOpen && (
    <div
      className={styles.backdrop}
      onClick={onCloseHandler}
    />
  );

  const optionsRender = (filteredOptions || options).map((option: any) => (
    <div
      key={option.value}
      className={styles.option}
      onClick={() => onSelectHandler(option)}
    >
      {option.description}
    </div>
  ));

  const noOptionsFound = (
    <small className={styles.auxiliaryText}>
      Nenhuma opção encontrada
    </small>
  );

  const loadingResults = (
    <small className={styles.auxiliaryText}>
      Carregando opções...
    </small>
  );

  const optionsContainerRender = isOpen && (
    <div className={styles.optionsContainer}>
      {
        isLoading
          ? loadingResults
          : optionsRender.length
            ? optionsRender
            : noOptionsFound
      }
    </div>
  );

  return (
    <>
      {backdropRender}

      <div className={styles.selectInput} style={{ zIndex: isOpen ? 10 : 0 }}>
        <div className={styles.input}>
          <TextInput
            value={searchValue}
            disabled={isLoading}
            onFocus={onOpenHandler}
            onChange={onSearchHandler}
            onTabPress={onCloseHandler}
            placeholder={selectedOption && selectedOption.description ? selectedOption.description : placeholder}
          />

          <div
            className={styles.collapseIconContainer}
            onClick={() => isOpen ? onCloseHandler() : onOpenHandler()}
          >
            <div
              className={isOpen
                ? styles.collapseIconOpen
                : styles.collapseIconClosed}
            >
              <span />
              <span />
            </div>
          </div>
        </div>

        {optionsContainerRender}
      </div>
    </>
  );
};

export default SelectInput;
