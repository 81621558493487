import { colors } from "./../../styles/index";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  text-align: center;
`;

export const DayColumn = styled.div`
  display: inline-block;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px;
`;

export const DayHeader = styled.div`
  border-top: 0px;
  border-right: 0px;
  border-left: 1px;
  border-bottom: 1px;
  border-color: ${colors.gray20};
  border-style: solid;
  font-weight: 400;
  font-size: 20px;
  color: ${colors.gray60};
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 10px 0px 0px 0px;
  width: 100%;
  height: 55px;
`;

export const TimeColumn = styled.div`
  display: inline-block;
  width: 50px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px;
`;
export const TimeHeader = styled.div`
  border: 0px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: ${colors.gray60};
  margin: 10px 0px 0px 0px;
  width: 50px;
  height: 55px;
`;

export const TimeCell = styled.div`
  border-style: solid;
  display: grid;
  align-items: end;
  text-align: right;
  justify-content: center;
  width: 50px;
  height: 90px;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 0px 7px 0px;
  color: ${colors.gray60};
  position: relative;
  &:before{
    content: "";
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: -1px;
    background-color: ${colors.gray20};
    
  }
`;

export const DrText = styled.h2`
  font-size: 12px;
  cursor: pointer;
  padding: 0px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  display: flex;
`;

export const PatientText  = styled.small`
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.gray60};
  max-width: 178px;
  overflow: hidden;
  padding: 2px 0px;
`;

export const Time = styled.span`
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3px
  white-space: nowrap;
  color: ${colors.gray60};
`;

export const Button = styled.button`
  position: relative;
  width: 100%;
  height: auto;
  align-content: center;
  justify-content: flex-start;
  color: ${colors.gray60};
  font-weight: 600;
  padding: 3px;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  background-color: ${colors.lightBlue};
  cursor: pointer;
  border-radius: 2px;
  &:hover{
    color: ${colors.white};
    background-color: ${colors.darkBlue};
  }
`;

export const ScheduleCell = styled.div`
  border-top: 0px;
  border-right: 0px;
  border-left: 1px;
  border-bottom: 1px;
  border-color: ${colors.gray20};
  border-style: solid;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows 70% 30%;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  max-width: 230px;
  height: 90px;
  margin: 0px;
  padding: 2px;
  overflow: hidden;
  &:hover {
    background-color:${colors.lightBlue};
  }
  div {
    &:nth-child(n+2) {
      display: none;
    }
  }
`;

export const ScheduledTime = styled.div`
  background-color: ${colors.gray20};
  display: grid;
  width: 100%;
  min-height: 100%;
  align-content: center;
  justify-content: flex-start;
  color: ${colors.white};
  border-radius: 2px;
  border: 1px solid ${colors.white};
  cursor: pointer;
  &:hover {
    background-color:${colors.gray40};
  }
`;

export const ScheduleAvailableTime = styled.div`
  background-color: ${colors.primary};
  display: grid;
  width: 100%;
  min-height: 100%;
  align-content: center;
  justify-content: flex-start;
  color: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.white};
  cursor: pointer;
  &:hover {
    background-color:${colors.darkBlue};
    color: 
  }
`;

export const ScheduleAvailableText = styled.div`
  font-size: 11px;
  font-weight: 400px;
  padding-left: 10px;
  span{
    color: #FFFFFF
  }
`;

export const ScheduledText = styled.div`
  font-size: 11px;
  font-weight: 400px;
  padding-left: 10px;
  color: ${colors.green}
`;