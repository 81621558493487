import styled from "styled-components";
import { colors } from "styles";

export const MultipleScheduleScreenRoot = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const WeekDaysRender = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CheckboxWrapper = styled.div`
  margin: 5px;
`;

export const AdditionalDatesRender = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AdditionalDateWrapper = styled.div`
  margin-top: 20px;
`;

export const AdditionalDateTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding-bottom: 8px;
  color: ${colors.gray70};
  font-family: "Nunito", sans-serif;
  border-bottom: 1px solid ${colors.gray40};
`;

export const SchedulePill = styled.div<any>`
  width: 60px;
  height: 32px;
  display: flex;
  cursor: pointer;
  margin-top: 5px;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: ${({selected}: any) => selected ? colors.white : colors.gray70};
  background-color: ${({selected}: any) => selected ? colors.primary : 'none'};
  border: ${({selected}: any) => selected ? colors.primary : `1px solid ${colors.gray70};`};
`;

export const SchedulePillsRender = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
