import { createGlobalStyle } from 'styled-components';
import { colors } from '../styles';

export default createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600&display=swap');

   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
   }

   html, body, #root{
      // height: 100%;
      /* background-color: ${colors.gray20}; */
   }

   *, button, input {
      border: 0;
      outline: 0;
      font-family: 'Nunito', sans-serif;
   }

   button {
      cursor: pointer;
   }
`;
