import styles from "./style.module.css";

const TextArea = ({ value, onChange = () => {}, placeholder, name }: any) => {
  const onChangeHandler = ({ target }: any) => {
    onChange(target.value, name);
  };

  return (
    <textarea
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChangeHandler}
      className={styles.textarea}
    />
  );
};

export default TextArea;
