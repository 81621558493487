import styled from "styled-components";
import { colors, lightColors } from "styles";

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${(props: any) => colors[props.color]};
  color: ${(props: any) =>
    lightColors[props.color] ? colors.grey60 : colors.white};
  box-shadow: none;
  border-radius: 4px;
  letter-spacing: 0.3px;
  transition: all 0.2s ease;
  font-size: 12px;
  padding: 8px 30px 8px 30px;
  border-radius: 5px;
  font-weight: 500;

  &:disabled {
    background-color: ${colors.gray20};
    background-color: ${colors.gray20};
  }
`;
