import { colors } from "./../../styles/index";
import styled from "styled-components";

export const Label = styled.div`
  color: ${colors.gray60};
  font-weight: 400;
  font-size: 11px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  margin-bottom: 10px;
  padding-left: 5px;
`;

export const BoxContainer = styled.div`
  display: flex;
  padding: 0px;
  justify-content: space-between;
`;

export const BoxChild = styled.div`
  margin: 5px;
`;


