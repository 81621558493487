import { applyMask, removeMask } from "helpers/mask";
import { useRef } from "react";
import styles from "./style.module.css";

const TextInput = (props: any) => {
  const {
    onEnterPress = () => {}, // function trigered when user press enter key
    onTabPress = () => {}, // function trigered when user press tab key
    onChange = () => {}, // function triggered when input changes
    onFocus = () => {}, // function triggered when user focus the input
    onBlur = () => {}, // function triggered when user clicks outside the input
    type = "text", // string to set the input type
    placeholder, // string to define the placeholder
    className, // className to personalize the component
    inputMode, // sets the inputmode (keyboard type, in theory)
    disabled, // booleand to disable input
    onStopTyping = () => {},
    value = "", // string to set the input value
    mask = "", // mask
    name,
  } = props;

  const keyPressHandler = ({ key }: any) => {
    if (key === "Enter") onEnterPress();
  };

  const keyDownHandler = ({ key }: any) => {
    if (key === "Tab") onTabPress();
  };

  const timeout = useRef<any>();

  const changeHandler = (event: any) => {
    const {
      target: { value },
    } = event || {};

    onChange(removeMask(value, mask), name);

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeout.current = setTimeout(() => {
      onStopTyping(removeMask(value, mask));
    }, 600);
  };

  return (
    <input
      {...props}
      type={type}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={applyMask(value, mask)}
      disabled={disabled}
      placeholder={placeholder}
      inputMode={inputMode}
      onChange={changeHandler}
      onKeyDown={keyDownHandler}
      onKeyPress={keyPressHandler}
      className={`${styles.input} ${className}`}
    />
  );
};

export default TextInput;
