import GetIcon from "components/Icons";
import { colors } from "styles";
import {
  Description, ModalHeader,
  Title,
  TitleWrapper
} from "./styles";

const Header = (props: any) => {
  const { title, icon, description } = props;

  return (
    <ModalHeader>
      <TitleWrapper>
        <GetIcon svgProps={{ fill: colors.primary }} icon={icon} />

        <Title>{title}</Title>
      </TitleWrapper>

      {description && (
        <Description>
          {description} &nbsp;
        </Description>
      )}
    </ModalHeader>
  );
};

export default Header;
