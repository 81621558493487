import { stopPropagation } from "components/AttendanceCreationForm/helper";
import MultipleScheduleScreen from "components/MultipleScheduleScreen";
import React from "react";
import Header from "./header";
import {
  ModalBody,
  ModalContainer,
  ModalOverlay,
  CenterModalComponent,
  RightModalContainer,
} from "./styles";

interface ModalProps {
  icon?: string;
  open?: boolean;
  title?: string;
  toggle: () => boolean | void;
  renderRightContainer: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { children, toggle, renderRightContainer } = props;

  return (
    <ModalOverlay onClick={toggle} {...props}>
      <ModalContainer onClick={stopPropagation}>
        <CenterModalComponent>
          <Header {...props} />
          <ModalBody>{children}</ModalBody>
        </CenterModalComponent>

        {renderRightContainer && (
          <RightModalContainer>
            <MultipleScheduleScreen />
          </RightModalContainer>
        )}

      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
