import axios from "axios";

const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzksImlhdCI6MTYzMzk3MzUyMiwiZXhwIjoxNjM0MTQzNTIyfQ.0ynRDOcGg7Ki760E2tL9e0eQIZKmUN9F_BlavX5tMEw";

export const api = axios.create({
  baseURL: "https://tony.veksti.com/api_homologacao",
  // baseURL: "http://localhost:8000/api/v1",
  headers: {
    "Content-Type": "application/json",
    "x-access-token": `Bearer ${TOKEN}`,
  },
});

export const integration = axios.create({
  baseURL: "https://tony.veksti.com/tasy/",
  // baseURL: "http://localhost:7800/api/",
  headers: {
    "x-access-token":
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjA0NzM1ODgxLCJleHAiOjE5MjAwOTU4ODF9.IitsHroajM9WFlfAXdLpRzpEkZCM5GA0sZ_UXeIkY8s",
  },
});
