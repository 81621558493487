export const genders = [
  {
    description: "Masculino",
    value: "M",
  },
  {
    description: "Feminino",
    value: "F",
  },
];

export const normalizePatientInformation = (patient) => ({
  name: patient.NOME,
  cpf: patient.CPF,
  rg: patient.RG,
  birthDate: patient.DATANASCIMENTO,
  gender: patient.SEXO,
  civilStatus: patient.ESTADOCIVIL,
  mainTelephoneNumber: patient.TELEFONE,
  secondaryTelephoneNumber: patient.NR_TELEFONE_RESP,
  email: patient.DS_EMAL,
  cep: patient.CEP,
  country: patient.PAIS,
  state: patient.ESTADO,
  city: patient.MUNICIPIO,
  district: patient.DESTRITO,
  address: patient.ENDERECO,
  number: patient.NUMERO,
  complement: patient.COMPLEMENTO,
  observation: null,
});

export const formatToLocaleDate = (date) => new Date(date).toLocaleDateString();

export const getWeekDayLong = (date) => {
  const options = { weekday: "long" };
  return new Date(date).toLocaleString("pt-BR", options);
};

export const stopPropagation = (e) => e.stopPropagation();

export const weekDays = [
  { day: 1, description: "Dom" },
  { day: 2, description: "Seg" },
  { day: 3, description: "Ter" },
  { day: 4, description: "Qua" },
  { day: 5, description: "Qui" },
  { day: 6, description: "Sex" },
  { day: 7, description: "Sáb" },
];

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) =>
  EMAIL_REGEX.test(String(email).toLowerCase());

export const isCpfDigitsValid = (value) => {
  let cpf = String(value);

  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const isValidBirthDate = (value) => {
  return !!value;
};

export const isFullName = (value) => {
  const name = String(value);

  if (!name || !name.length) return false;

  const splittedBySpace = name.split(" ");
  return splittedBySpace.length > 1;
};

export const isValidCep = (value) => {
  const cep = String(value);
  const validacep = /^[0-9]{8}$/;

  if (validacep.test(cep)) {
    return true;
  } else {
    return false;
  }
};

export const isValidAddress = (value) => {
  const street = String(value);

  if (!street || !street.length) {
    return false;
  } else {
    return true;
  }
};

export const isCityValid = (value) => {
  const city = String(value);

  if (!city || !city.length) {
    return false;
  } else {
    return true;
  }
};

export const isStateValid = (value) => {
  const state = String(value);

  if (!state || !state.length) {
    return false;
  } else {
    return true;
  }
};

export const isDistrictValid = (value) => {
  const district = String(value);

  if (!district || !district.length) {
    return false;
  } else {
    return true;
  }
};

export const isCountryValid = value => {
  const country = String(value)

  if(!country || !country.length){
    return false 
  } else { 
    return true
  }
};

export const isNumberValid = value => { 
  const numberOfHouse = String(value)

  if(!numberOfHouse || !numberOfHouse.length){
    return false
  } else {
    return true
  }
};

export const isInsuranceValid = value => { 
  const insurance = String(value.nome)

  if(!insurance || !insurance.length){
    return false
  } else {
    return true
  }
}
