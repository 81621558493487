import { colors } from "../../styles/index";
import styled from "styled-components";

export const SideMenu = styled.div`
  margin: 10px;
`;
export const CalendarScreen = styled.div`
  padding-left: 25px
`;
export const Container = styled.div`
  display: grid;
  grid-template-columns: 260px auto;
`;
