import styled, { keyframes } from "styled-components";
import { colors } from "./../../styles/index";


export const LoadingContainer = styled.div`
  border: 16px solid ${colors.white};
  border-radius: 50%;
  border-top: 16px solid ${colors.primary};
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  &:spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const pulse = keyframes`
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  `;

export const CustomerLogo = styled.img`
  width: 100%;
  height: 100%;
  animation: ${pulse} 1.5s infinite;
`;
