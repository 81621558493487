import styled from "styled-components";
import { colors } from "styles";

export const AttendanceConfirmationRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectedPatientName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.gray80};
`;

export const SelectedProfessionalName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.gray80};
`;

export const SelectedPatientInformation = styled.div`
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.gray40};
`;

export const SelectedScheduleInformation = styled.div`
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const Information = styled.div`
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray80};
`;

export const Badge = styled.div`
  font-size: 11px;
  margin-left: 15px;
  font-weight: bold;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.03em;
  padding: 4px 8px 4px 8px;
  text-transform: uppercase;
  background-color: ${colors.primary};
  color:  ${colors.white};
`;

export const BadgeText = styled.div`
  margin-left: 5px;
`

export const SelectedSchedules = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const ScheduleBadge = styled.div`
  display: flex;
  font-size: 11px;
  margin-right: 10px;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  margin-bottom: 5px;
  align-items: center;
  letter-spacing: 0.03em;
  color:  ${colors.white};
  justify-content: center;
  padding: 8px 12px 8px 12px;
  background-color: ${colors.gray80};
  text-transform: capitalize;
`;

export const AttendanceConfirmationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`
export const Observation = styled.div`
  margin-top: 30px;
`