import GetIcon from 'components/Icons';
import { useRef, useState } from 'react';
import TextInput from '../TextInput';
import styles from './style.module.css';

const SearchBar = (props: any) => {
  const {
    isLoading, // boolean to disable the input
    placeholder, // string to set placeholder
    onSearch = () => {}, // function trigered after user stops typing
  } = props;

  const [ searchTerm, setSearchTerm ] = useState('');
  const timeout = useRef<any>();

  const onChangeHandler = (term: any) => {
    setSearchTerm(term);

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeout.current = setTimeout(() => {
      onSearch(term);
    }, 1500);
  };

  return (
    <div className={styles.searchBar}>
      <TextInput
        onChange={onChangeHandler}
        placeholder={placeholder}
        disabled={isLoading}
        value={searchTerm}
      />

      <GetIcon icon="searchBar"/>


    </div>
  );
};

export default SearchBar;
