import ProfessionalFilter from "components/ProfessionalFilter";
import LoadingOverlay from "components/LoadingOverlay";
import { useEffect, useState } from "react";
import { getAgendamentosTasy } from "../../services/Agendamentos";
import { getProfessional, getServices } from "../../services/Professional";
import { getUserInformation } from "services/User";

import {
  DivRow,
  DivCol,
  DatePicker,
  Label,
  DivCard,
  Button,
  ButtonCancel,
  Container,
  ContainerTable,
  NoData,
} from "./style";
import { Table, Tbody, Tr, Td } from "./style";
import Camera from "../Icons/camera";
import CheckboxComponent from "components/CheckBox";
import Location from "../Icons/location";
import SearchBar from "components/SearchBar";
import Specialty from "../../components/Specialty";
interface ILayout {
  dateFilter: Date,
  professionalCpf: string,
  specialty: number,
}

const Agendamentos: React.FC<ILayout> = (props) => {

  const { dateFilter, professionalCpf, specialty } = props
  const [data, setData] = useState([]);
  const [filterDate, setFilterDate] = useState(dateFilter ? dateFilter : new Date());
  const [loading, setLoading] = useState(false);
  const [selectedProfessionalIdList, setSelectedProfessionalIdList] = useState<Array<string>>([professionalCpf]);
  const [professionals, setProfessionals] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState<number>(specialty);
  const [specialties, setSpecialties] = useState<Array<{ id: number; label: string }>>(new Array<{ id: number; label: string }>());
  const [patient, setPatient] = useState<string>();

  const dataAgendamentos = async () => {
    let searchTerm = ``;

    if (!selectedProfessionalIdList || selectedProfessionalIdList.length === 0 || selectedProfessionalIdList.toString() === '') return;

    if (selectedProfessionalIdList) {
      searchTerm = `${searchTerm}&medicos=${selectedProfessionalIdList.toString()}`;
    }

    // if (selectedSpecialty) {
    //   searchTerm = `${searchTerm}&especialidades=${selectedSpecialty}`;
    // }

    if (patient) {
      searchTerm = `${searchTerm}&cpfPaciente=${patient}`;
    }

    try {
      setLoading(true);
      if (filterDate) {
        let arrDate = filterDate.toISOString().substring(0, 10).split('-')
        let dateToCompar = new Date(parseInt(arrDate[0]), parseInt(arrDate[1])-1, parseInt(arrDate[2]));

        let searchTermDate = `isSingleDate=true`;
        searchTerm = `${searchTerm}&${searchTermDate}&data=${dateToCompar.toLocaleDateString(
          "pt-BR",
          { day: "numeric" }
        )}/${dateToCompar.toLocaleDateString("pt-BR", {
          month: "numeric",
        })}/${dateToCompar.toLocaleDateString("pt-BR", { year: "numeric" })}`;

      }

      const response = await getAgendamentosTasy(searchTerm);

      setData(
        response.data.map((item: any) => {
          return {
            profissional: item.DS_AGENDA,
            dataagendamento: item.DT_AGENDA,
            paciente: item.NM_PACIENTE,
            horario: item.HR_AGENDA,
            live: item.remoto,
            status: item.status,
            contato: item.contato,
          };
        })
      );
    } catch (err) {
      console.log("Erro: ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dataAgendamentos();
  }, [filterDate]);

  useEffect(() => {
    async function getInformation() {
      try {
        const {
          data: { data },
        } = await getProfessional();

        setProfessionals(
          data.map((profissional: any) => {
            return {
              label: profissional.nome,
              selected: selectedProfessionalIdList.toString().includes(profissional.cpf),
              id: profissional.id,
            };
          })
        );

        setSelectedProfessionalIdList([]);

        const servicos = await getServices();

        setSpecialties(
          servicos.data.data.map((specialty: any) => {
            return {
              id: specialty.id,
              label: specialty.nome,
            };
          })
        );
      } catch (error) {
        console.log(error);
        // alert("Ocorreu um erro ao retornar os pacientes \n" + error);
      } finally {
        // handleLoadings("mounting", false);
      }
    }

    getInformation();
  }, []);

  useEffect(() => {
    async function getInformation() {
      try {
        let searchTerm = ``;

        if (selectedSpecialty) {
          searchTerm = `${searchTerm}&especialidade=${selectedSpecialty}`;
        }

        const {
          data: { data },
        } = await getProfessional(searchTerm);

        setProfessionals(
          data.map((profissional: any) => {
            return {
              label: profissional.nome,
              selected: selectedProfessionalIdList.toString().includes(profissional.cpf),
              id: profissional.cpf,
            };
          })
        );
        setSelectedProfessionalIdList([]);
      } catch (error) {
        console.log(error);
        // alert("Ocorreu um erro ao retornar os pacientes \n" + error);
      } finally {
      }
    }

    getInformation();
  }, [selectedSpecialty]);

  useEffect(() => {
    if(!selectedProfessionalIdList || selectedProfessionalIdList.length === 0) return;
    dataAgendamentos();
  }, [selectedProfessionalIdList]);

  const onSearchPatient = (term: any) => {
    getUserInformation(term)
      .then((response) => {
        const {
          data: [user],
        } = response;

        if (!user) {
          alert("Nenhum paciente com esse CPF foi encontrado");
        }

        setPatient(user.CPF);
      })
      .catch((_) => {
        alert("Nenhum paciente com esse CPF foi encontrado");
      });
  };

  useEffect(() => {
    if(!dateFilter) return;
    setFilterDate(dateFilter);
  }, [dateFilter])

  useEffect(() => {
    if(!professionalCpf) return;
    setSelectedProfessionalIdList([professionalCpf]);
  }, [professionalCpf])

  useEffect(() => {
    if(!specialty) return;
    setSelectedSpecialty(specialty);
  }, [specialty])

  return (
    <Container>
      {/* <LoadingOverlay /> */}
      <DivCard>
        <DivRow>
          <DivCol style={{marginBottom: '10px'}}>
          <Label style={{marginLeft: '4px'}}>Buscar por Paciente: </Label>
            <SearchBar
              onSearch={onSearchPatient}
              placeholder="Pesquisar pelo CPF..."
            />
          </DivCol>

          <DivCol>
            <Specialty
              selectedSpecialty={selectedSpecialty}
              setSelectedSpecialty={setSelectedSpecialty}
              specialties={specialties}
            />
          </DivCol>

          <DivCol>
            <ProfessionalFilter
              professionals={professionals}
              selectedIdList={selectedProfessionalIdList}
              setSelectedIdList={setSelectedProfessionalIdList}
            />
          </DivCol>

          <DivCol>
            <Label>Filtrar por Data:</Label>
            <DatePicker
              value={filterDate.toISOString().substring(0, 10)}
              type="date"
              onChange={(e: any) => {
                let date = e.target.value.split('-')
                setFilterDate(new Date(date[0], date[1]-1, date[2]))
              }}
            />
          </DivCol>
        </DivRow>
      </DivCard>
      <ContainerTable>
      <Table>
        <Tbody>
        {data &&
          data.length > 0 &&
          data.map((data: any) => (
            <>
              <Tr>
                <Td>{data.live ? <Camera /> : <Location />}</Td>
                <Td>{data.paciente}</Td>
                <Td>Dr(a). {data.profissional}</Td>
                <Td>
                  {new Date(data.dataagendamento).toLocaleString("pt-BR", {
                    timeZone: "UTC",
                    year: "numeric",
                    day: "2-digit",
                    month: "2-digit",
                  })}
                </Td>
                <Td>{data.horario.slice(0, 5)}</Td>
                <Td>{data.status}</Td>
                <Td>
                  <CheckboxComponent
                    selected={!!data.contato}
                    label="Contato"
                    onClick={() => {}}
                  />
                </Td>
                <Td style={{textAlign: 'center'}}>
                  <Button>Enviar Email</Button>
                  <Button>Enviar SMS</Button>
                  <ButtonCancel>Cancelar</ButtonCancel>
                </Td>
              </Tr>
            </>
          ))}
          {!data ||
          data.length === 0 &&(
            <NoData>
              Nenhuma agenda encontrada.
            </NoData>
          )}
        </Tbody>
      </Table>
      </ContainerTable>
    </Container>
  );
};

export default Agendamentos;
