import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ? props.width : 15}
      height={props.height ? props.height : 15}
      fill="#838484"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4.5325C0 2.033 2.11514 0 4.71429 0C7.31343 0 9.42857 2.033 9.42857 4.5325C9.42857 8.084 5.15743 11.751 4.97567 11.9055C4.90076 11.9685 4.80752 12 4.71429 12C4.62105 12 4.52781 11.9685 4.45291 11.905C4.27114 11.751 0 8.084 0 4.5325ZM4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6Z"
        fill={props.fill}
      />
    </svg>
  )
}

export default SvgComponent
