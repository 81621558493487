/*
  ApplyMask and RemoveMask:
  Functions expects a value and a mask with 'x' on the valid chars. E.g.:
    -> CPF mask whould be xxx.xxx.xxx-xx
    -> Cellphone mask whould be (xx) xxxxx-xx
*/

export const applyMask = (value = '', mask = '') => {
    if (!mask) {
      return value;
    }
  
    const splittedMask = mask?.split('') || [];
    const splittedValue = value?.split('') || [];
  
    const {masked} = splittedMask.reduce(
      (currentVal, char) => {
        const {remainingValueChars, masked} = currentVal;
  
        if (!remainingValueChars || !remainingValueChars.length) {
          return {...currentVal};
        }
  
        if (char === 'x') {
          return {
            masked: [...masked, remainingValueChars[0]],
            remainingValueChars: [
              ...remainingValueChars.slice(1, remainingValueChars.lenght),
            ],
          };
        }
  
        return {
          masked: [...masked, char],
          remainingValueChars: [...remainingValueChars],
        };
      },
      {
        masked: [],
        remainingValueChars: [...splittedValue],
      },
    );
  
    return masked.join('');
  };
  
export const removeMask = (value = '', mask= '') => {
    if (!mask) return value;
  
    const splittedMask = mask?.toString().split('');
    const splittedValue = value?.toString().split('');
  
    const unMasked = splittedMask.reduce((currentValue, char) => {
      if (char === 'x') {
        return [...currentValue];
      }
  
      const newVal = [...currentValue];
      const indexOfChar = newVal.indexOf(char);
  
      if (indexOfChar < 0) {
        return newVal;
      }
  
      newVal.splice(indexOfChar, 1);
  
      return newVal;
    }, splittedValue);
  
    const maskCharsQuantity = splittedMask.filter(char => char === 'x').length;
  
    return unMasked.slice(0, maskCharsQuantity).join('');
  };
  
  