import { colors } from "./../../styles/index";
import styled from "styled-components";

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  color: ${colors.gray60};
  margin-left: 5px;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 10px;
`;

export const NoData = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 15px;
  color: ${colors.gray40};
`;

export const Container = styled.div`
  margin-bottom: 10px;
  width: 250px;
`;