import Button from "components/Button";
import CheckboxComponent from "components/CheckBox";
import GetIcon from "components/Icons";
import InsuranceSelection from "components/InsuranceSelection";
import Label from "components/Label";
import SearchBar from "components/SearchBar";
import SelectInput from "components/SelectInput";
import TextInput from "components/TextInput";
import { useConfigurations } from "hooks/configurations";
import { viaCepGet } from "services/Cep";
import { getUserInformation } from "services/User";
import { genders, normalizePatientInformation } from "./helper";
import {
  ButtonText,
  CheckboxGroup,
  CheckboxWrapper,
  FormField,
  FormFields,
  FormFooter,
  FormFooterOptions,
  FormHeader,
  FormRoot,
  FormSubmitArea,
  ErrorLabel,
} from "./styles";

export default function AttendanceCreationForm() {
  const {
    errors = {},
    validateInput,
    handleInputEvent,
    handleAttendanceFields,
    attendanceCreationFields,
    setAttendanceCreationStep,
    switchShowAdditionalSchedule,
  } = useConfigurations();

  console.log(attendanceCreationFields)

  const isValidForm =
    attendanceCreationFields.cpf &&
    attendanceCreationFields.rg &&
    attendanceCreationFields.civilStatus &&
    attendanceCreationFields.name &&
    attendanceCreationFields.email &&
    attendanceCreationFields.birthDate &&
    attendanceCreationFields.mainTelephoneNumber &&
    attendanceCreationFields.cep &&
    attendanceCreationFields.insurance.value !== "" &&
    !Object.keys(errors).length;

  const bulkInsert = (values: any) => {
    const keys = Object.keys(values);
    keys.forEach((key) => handleAttendanceFields(key, values[key]));
  };

  const getAddress = () => {
    viaCepGet(attendanceCreationFields.cep).then(
      ({ data: { erro, bairro, complemento, localidade, logradouro, uf } }) => {
        if (erro) {
          alert("CEP não encontrado");
          return;
        }

        bulkInsert({
          district: bairro,
          complement: complemento,
          city: localidade,
          address: logradouro,
          state: uf,
        });
      }
    );
  };

  const onSearchPatient = (term: any) => {
    getUserInformation(term)
      .then((response) => {
        const {
          data: [user],
        } = response;

        const normalizedPatientInformation = normalizePatientInformation(user);

        bulkInsert(normalizedPatientInformation);
      })
      .catch((_) => {
        alert("Nenhum paciente com esse CPF foi encontrado");
      });
  };

 

  const handleSubmitForm = () => {
    setAttendanceCreationStep("confirmation");
  };

  return (
    <FormRoot>
      <FormHeader>
        <FormField>
          <Label label="Buscar Paciente:">
            <SearchBar
              onSearch={onSearchPatient}
              placeholder="Pesquisar pelo CPF..."
            />
          </Label>
        </FormField>

        <FormField>
          <Button onClick={switchShowAdditionalSchedule} color="primary">
            <GetIcon svgProps={{ fill: "white", width: 15 }} icon="clock" />
            <ButtonText>Adicionar mais horários</ButtonText>
          </Button>
        </FormField>
      </FormHeader>

      <FormFields>
        <FormField width="25">
          <Label required label="Nome:">
            <TextInput
              onStopTyping={(val: any) => validateInput("name", val)}
              value={attendanceCreationFields.name}
              onChange={handleInputEvent}
              name="name"
            />
            {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="20">
          <Label required label="CPF:">
            <TextInput
              name="cpf"
              onStopTyping={(val: any) => validateInput("cpf", val)}
              value={attendanceCreationFields.cpf}
              onChange={handleInputEvent}
              mask="xxx.xxx.xxx-xx"
            />
            {errors.cpf && <ErrorLabel>{errors.cpf}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="15">
          <Label required label="RG:">
            <TextInput
              value={attendanceCreationFields.rg}
              onChange={handleInputEvent}
              name="rg"
            />
          </Label>
        </FormField>

        <FormField width="20">
          <Label required label="Data de Nascimento:">
            <TextInput
              onStopTyping={(val: any) => validateInput("birthDate", val)}
              value={attendanceCreationFields.birthDate}
              onChange={handleInputEvent}
              name="birthDate"
              type="date"
            />
            {errors.birthDate && <ErrorLabel>{errors.birthDate}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="20">
          <Label label="Gênero:">
            <SelectInput
              selectedOption={attendanceCreationFields.gender}
              name="gender"
              onSelect={(val: any) => handleAttendanceFields("gender", val)}
              options={genders}
              placeholder="Selecione..."
            />
          </Label>
        </FormField>

        <FormField width="25">
          <Label required label="Estado Civil:">
            <TextInput
              value={attendanceCreationFields.civilStatus}
              onChange={handleInputEvent}
              name="civilStatus"
            />
          </Label>
        </FormField>

        <FormField width="25">
          <Label required label="Telefone 1:">
            <TextInput
              onStopTyping={(val: any) =>
                validateInput("mainTelephoneNumber", val)
              }
              mask="(xx) xxxxx-xxxx"
              value={attendanceCreationFields.mainTelephoneNumber}
              onChange={handleInputEvent}
              name="mainTelephoneNumber"
            />
            {errors.mainTelephoneNumber && (
              <ErrorLabel>{errors.mainTelephoneNumber}</ErrorLabel>
            )}
          </Label>
        </FormField>

        <FormField width="25">
          <Label label="Telefone:">
            <TextInput
              mask="(xx) xxxx-xxxx"
              value={attendanceCreationFields.secondaryTelephoneNumber}
              onChange={handleInputEvent}
              name="secondaryTelephoneNumber"
            />
            
          </Label>
        </FormField>

        <FormField width="25">
          <Label required label="E-mail:">
            <TextInput
              onStopTyping={(val: any) => validateInput("email", val)}
              value={attendanceCreationFields.email}
              onChange={handleInputEvent}
              name="email"
            />
            
          </Label>
        </FormField>

        <FormField width="15">
          <Label required label="CEP:">
            <TextInput
              onStopTyping={(val: any) => validateInput("cep", val)}
              value={attendanceCreationFields.cep}
              onChange={handleInputEvent}
              onBlur={getAddress}
              name="cep"
              mask="xx.xxx-xxx"
            />

            {errors.cep && <ErrorLabel>{errors.cep}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="20">
          <Label label="País:">
            <TextInput
            onStopTyping={(val: any) => validateInput("country", val)}
              value={attendanceCreationFields.country}
              onChange={handleInputEvent}
              name="country"
            />
            {errors.country && <ErrorLabel>{errors.country}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="10">
          <Label label="Estado:">
            <TextInput
            onStopTyping={(val: any) => validateInput("state", val)}
              value={attendanceCreationFields.state}
              onChange={handleInputEvent}
              name="state"
            />
            {errors.state && <ErrorLabel>{errors.state}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="30">
          <Label label="Cidade:">
            <TextInput
              onStopTyping={(val: any) => validateInput("city", val)}
              value={attendanceCreationFields.city}
              onChange={handleInputEvent}
              name="city"
            />
            {errors.city && <ErrorLabel>{errors.city}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="25">
          <Label label="Bairro:">
            <TextInput
            onStopTyping={(val: any) =>  validateInput("district", val)}
              value={attendanceCreationFields.district}
              onChange={handleInputEvent}
              name="district"
            />
            {errors.district && <ErrorLabel>{errors.district}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="50">
          <Label label="Endereço:">
            <TextInput
              onStopTyping={(val: any) => validateInput("address", val)}
              value={attendanceCreationFields.address}
              onChange={handleInputEvent}
              name="address"
            />
             {errors.address && <ErrorLabel>{errors.address}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="10">
          <Label label="Número:">
            <TextInput
            onStopTyping={(val: any) => validateInput("number", val)}
              value={attendanceCreationFields.number}
              onChange={handleInputEvent}
              name="number"
            />
            {errors.number && <ErrorLabel>{errors.number}</ErrorLabel>}
          </Label>
        </FormField>

        <FormField width="40">
          <Label label="Complemento:">
            <TextInput
              value={attendanceCreationFields.complement}
              onChange={handleInputEvent}
              name="complement"
            />
          </Label>
        </FormField>
      </FormFields>

      <FormFooter>
        <FormFooterOptions>
          <FormField>
            <Label label="Convênio:">
              <InsuranceSelection
                selectedOption={attendanceCreationFields.insurance}
                onSelect={(value: any) => handleInputEvent(value, "insurance")}
              />
              {errors.insurance && <ErrorLabel>{errors.insurance}</ErrorLabel>}
            </Label>
          </FormField>

          <FormField>
            <Label label="Tipo do atendimento:">
              <CheckboxGroup>
                <CheckboxWrapper>
                  <CheckboxComponent
                    onClick={() => handleInputEvent(true, "inPerson")}
                    selected={attendanceCreationFields.inPerson}
                    label="Presencial"
                  />
                </CheckboxWrapper>

                <CheckboxWrapper>
                  <CheckboxComponent
                    onClick={() => handleInputEvent(false, "inPerson")}
                    selected={!attendanceCreationFields.inPerson}
                    label="Telemedicina"
                  />
                </CheckboxWrapper>
              </CheckboxGroup>
            </Label>
          </FormField>
        </FormFooterOptions>
        <FormSubmitArea>
          <Button
            disabled={!isValidForm}
            onClick={handleSubmitForm}
            color="primary"
          >
            Salvar agendamento
          </Button>
        </FormSubmitArea>
      </FormFooter>
    </FormRoot>
  );
}
